import { useQuery } from '@tanstack/react-query';
import { getLocationsChunked } from '../api/location';

const LOCATIONS = 'LOCATIONS';

export function useLocations(locationCodes: string[]) {
  return useQuery({
    queryKey: [LOCATIONS, locationCodes],

    queryFn: async () => {
      return await getLocationsChunked({ query: { code: locationCodes }, chunkKey: 'code' });
    },

    enabled: locationCodes.length > 0,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
