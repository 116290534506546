import NavBarContainer from './NavBarContainer';
import Link from 'next/link';
import Icon from '../../Icon';
import { colors, spacing } from '../../../../utils/styleguide';
import StaticHeaderWrapper from './StaticHeaderWrapper';
import styled from '@emotion/styled';

const Navigation = styled.div`
  pointer-events: auto;
`;

const RightAlignedContent = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 80px;
  align-items: center;
`;

const PartnerLogo = styled.img`
  height: 30px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: ${spacing[2]}px;
  margin-right: ${spacing[2]}px;
  border-right: 1px solid ${colors.white};
  filter: brightness(0) invert(1);
`;

export default function PoweredByMWNavBar({
  color = colors.white,
  partnerLogoSrc,
}: {
  color?: string;
  partnerLogoSrc?: string | null;
}) {
  return (
    <StaticHeaderWrapper>
      <>
        <NavBarContainer hideBorder>
          <RightAlignedContent>
            {partnerLogoSrc && <PartnerLogo src={partnerLogoSrc} />}
            <Navigation>
              <Link href="/" passHref>
                <Icon name="powered-by-milkywire" fill={color} size={80} />
              </Link>
            </Navigation>
          </RightAlignedContent>
        </NavBarContainer>
      </>
    </StaticHeaderWrapper>
  );
}
