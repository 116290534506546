import { Document } from '@contentful/rich-text-types';
import { GetServerSidePropsContext } from 'next';
import {
  isTypeGenericPageTemplate,
  TypeGenericPageTemplate,
  TypeGenericPageTemplateSkeleton,
  TypePillarSkeleton,
  TypePlainTextPageTemplate,
  TypePlainTextPageTemplateSkeleton,
} from '../../@types/generated';
import MainHero from '../common-components/component-library/heroes/MainHero';
import CTASectionLayout from '../common-components/component-library/layout/CTASectionLayout';
import FormCTA, { toFormCTASection } from '../common-components/component-library/layout/FormCTA';
import FormSection, {
  FormSectionProps,
} from '../common-components/component-library/layout/FormSection';
import MapMetricsLayout from '../common-components/component-library/layout/MapMetricsLayout';
import PartnerLogosLayout from '../common-components/component-library/layout/PartnerLogosLayout';
import HighlightedProfilesLayout from '../common-components/component-library/layout/highlightedProfilesLayout/HighlightedProfilesLayout';
import {
  MetaHeaderProps,
  transformMetaHeaderType,
} from '../common-components/contentful-elements/MetaHeader';
import {
  createTemplatePage,
  getLandingPageServerSideProps,
} from '../common-components/contentful-elements/pages/template';
import { TemplatePageProps } from '../common-components/contentful-elements/util/template';
import { getEntries } from '../utils/api/contentful';
import config from '../utils/config';
import { UrlError } from '../utils/error';
import { LOCALES } from '../utils/locale';
import withAppProps from '../utils/withAppProps';
import { useContentfulMediaOrientation } from '../utils/hooks/useContentfulMediaOrientation';
import { getInitiatives, getPillars } from '../utils/api';
import { hasValue } from '../utils/typeCheck';
import Highlight, {
  type HighlightProps,
  createHighlightImage,
  createHighlightVideo,
} from '../utils/templatePages/Highlight';
import { linksToButtons } from '../utils/templatePages/linksToButtons';
import { colors } from '../utils/styleguide';
import QuestionsAndAnswers from '../common-components/component-library/layout/accordionLayout/QuestionsAndAnswers';
import PageHero from '../common-components/component-library/heroes/PageHero';
import PrimaryButton from '../common-components/component-library/buttons/PrimaryButton';

const TopLevelPage = createTemplatePage({ campaignName: 'top-level-page' });

const getOldConfiguration = (ctx: GetServerSidePropsContext) => {
  const { query, locale: language, defaultLocale } = ctx;
  const name: string = query.name as string;
  const locale = LOCALES[language ?? defaultLocale ?? 'en'];
  return {
    slug: name,
    tags: ['Top level'],
    locale,
  };
};

export type GenericPageProps = {
  isGenericPage: boolean;
  title?: string;
  hero: Partial<{
    title: string;
    paragraph: string;
    image: TypeGenericPageTemplate['fields']['heroImage'];
    buttons: Array<
      Partial<{
        text: string;
        url: string;
      }>
    >;
  }>;
  highlight1: HighlightProps;
  highlight2: HighlightProps;
  highlight3: HighlightProps;
  metrics: Partial<{
    title: string;
    mapItems: Array<{ label: string; locationCodes: string[] }>;
    values: Array<
      Partial<{
        metricName: string;
        metricValue: string;
      }>
    >;
  }>;
  downloadForm: FormSectionProps;
  experts: Partial<{
    sectionTitle: string;
    title: string;
    text: string;
    mainExpertsTitle: string;
    mainExperts: Array<
      Partial<{
        name: string;
        title: string;
        imageUrl: string;
        imageAlt: string;
        description: Document;
      }>
    >;
    secondaryExpertsTitle: string;
    secondaryExperts: Array<
      Partial<{
        name: string;
        title: string;
        imageUrl: string;
        imageAlt: string;
      }>
    >;
  }>;
  callToAction: Partial<{
    sectionTitle: string;
    text: string;
    buttons: Array<{
      label?: string;
      href?: string;
    }>;
    backgroundImageUrl: string;
  }>;

  highlight4: HighlightProps;
  partnerLogos: Partial<{
    title: string;
    logosList: Array<
      Partial<{
        link: string;
        image: {
          url: string;
          name?: string;
          alt: string;
        };
      }>
    >;
  }>;
  contactUs: Partial<{
    sectionTitle: string;
    title: string;
    sections: Array<
      Partial<{
        title: string;
        description: Document;
        link: Partial<{
          title: string;
          url: string;
        }>;
      }>
    >;
  }>;
};

export type PlainTextPageProps = {
  isPlainTextPage: boolean;
  heroSectionTitle?: string;
  heroParagraph?: string;
  heroCTAs?: { label?: string; href?: string }[];
  mainContent: {
    sectionTitle?: string;
    text?: Document;
  };
  faqSectionTitle?: string;
  faqTitle?: string;
  faqPreamble?: string;
  faqSectionReferences?: {
    title?: string;
    description?: Document;
  }[];
  contactUsSectionTitle?: string;
  contactUsTitle?: string;
  contactUsSections?: Array<
    Partial<{
      title: string;
      description: Document;
      link: Partial<{
        title: string;
        url: string;
      }>;
    }>
  >;
};

export function PlainTextPageTemplate(props: PlainTextPageProps) {
  const {
    heroSectionTitle,
    heroParagraph,
    heroCTAs,
    mainContent,
    faqSectionTitle,
    faqTitle,
    faqPreamble,
    faqSectionReferences,
    contactUsSectionTitle,
    contactUsTitle,
    contactUsSections,
  } = props;

  return (
    <>
      <PageHero title={heroSectionTitle} preamble={heroParagraph}>
        {heroCTAs?.map((cta, index) => (
          <PrimaryButton
            key={index}
            label={cta.label}
            href={cta.href}
            replace={false}
            color="purple"
            variant={index === 0 ? 'solid' : 'outline'}
          />
        ))}
      </PageHero>
      <Highlight {...mainContent} backgroundColor={colors.grey50} withCheckmark={false} />
      <QuestionsAndAnswers
        sectionTitle={faqSectionTitle}
        title={faqTitle}
        preamble={faqPreamble}
        faqSectionReferences={faqSectionReferences}
        backgroundColor={colors.white}
      />
      <FormCTA
        ctaFormSectionTitle={contactUsSectionTitle}
        ctaFormTitle={contactUsTitle}
        ctaFormSections={contactUsSections}
        backgroundColor={colors.grey50}
      />
    </>
  );
}

export const getPlainTextPageServerSideProps = async ({
  page,
  slug,
}: {
  page: TypePlainTextPageTemplate;
  slug: string;
}) => {
  const { fields } = page;

  return {
    props: {
      isPlainTextPage: true,
      heroSectionTitle: fields.heroSectionTitle,
      heroParagraph: fields.heroParagraph,
      heroCTAs: fields.heroCTAs?.map((cta) => ({
        label: cta?.fields.identifier,
        href: cta?.fields.externalUrl,
      })),
      mainContent: {
        sectionTitle: fields.mainContentSectionTitle,
        text: fields.mainContentText,
      },
      faqSectionTitle: fields.fAQSectionTitle,
      faqTitle: fields.fAQTitle,
      faqPreamble: fields.fAQPreamble,
      faqSectionReferences: fields.fAQSectionReferences?.filter(hasValue).map((faq) => ({
        title: faq?.fields.title,
        description: faq?.fields.description,
      })),
      contactUsSectionTitle: page.fields.contactUsSectionTitle,
      contactUsTitle: page.fields.contactUsTitle,
      contactUsSections: toFormCTASection(fields.contactUsSections),
    },
    pageAppProps: {
      metaHeader: transformMetaHeaderType({
        metaHeader: page.fields.metaHeader,
        overrides: { canonicalLink: `${config.HOST_URL}/${slug}` },
      }),
    },
  };
};

export const GenericPageTemplate = function (props: GenericPageProps) {
  const {
    hero,
    highlight1,
    highlight2,
    highlight3,
    highlight4,
    metrics,
    downloadForm,
    experts,
    callToAction,
    partnerLogos,
    contactUs,
  } = props;

  const orientedHeroImage = useContentfulMediaOrientation(hero.image);

  return (
    <>
      <MainHero
        sectionTitle={hero.title}
        paragraph={hero.paragraph}
        imageUrl={orientedHeroImage?.fields.file?.url}
        backgroundImageAlt={orientedHeroImage?.fields.description}
        primaryButtonLabel={hero.buttons?.[0]?.text}
        primaryButtonHref={hero.buttons?.[0]?.url}
        secondaryButtonLabel={hero.buttons?.[1]?.text}
        secondaryButtonHref={hero.buttons?.[1]?.url}
      />

      <Highlight {...highlight1} />

      <FormSection
        id={downloadForm.sectionTitle}
        sectionTitle={downloadForm.sectionTitle}
        title={downloadForm.title}
        description={downloadForm.description}
        form={downloadForm.form}
      />

      <HighlightedProfilesLayout
        id={experts.sectionTitle}
        sectionTitle={experts.sectionTitle}
        title={experts.title}
        paragraph={experts.text}
        primaryProfilesHeadline={experts.mainExpertsTitle}
        primaryProfiles={experts.mainExperts}
        secondaryProfilesHeadline={experts.secondaryExpertsTitle}
        secondaryProfiles={experts.secondaryExperts}
      />

      <Highlight {...highlight2} rightAlignedCopy />

      <Highlight {...highlight3} />

      <MapMetricsLayout
        id={metrics.title}
        mapItems={metrics.mapItems}
        sectionTitle={metrics.title}
        metrics={metrics.values}
      />

      <CTASectionLayout
        id={callToAction.sectionTitle}
        sectionTitle={callToAction.sectionTitle}
        title={callToAction.text}
        buttons={callToAction.buttons}
        backgroundImageUrl={callToAction.backgroundImageUrl}
      />
      <Highlight {...highlight4} rightAlignedCopy />
      <PartnerLogosLayout
        id={partnerLogos.title}
        title={partnerLogos.title}
        logosList={partnerLogos.logosList}
      />
      <FormCTA
        id={contactUs.sectionTitle}
        ctaFormSectionTitle={contactUs.sectionTitle}
        ctaFormTitle={contactUs.title}
        ctaFormSections={contactUs.sections}
      />
    </>
  );
};

export async function getPageProps(
  page: TypeGenericPageTemplate | TypePlainTextPageTemplate,
): Promise<{
  props: GenericPageProps | PlainTextPageProps;
  pageAppProps: {
    metaHeader?: MetaHeaderProps;
  };
}> {
  if (isTypeGenericPageTemplate(page)) {
    const { metaHeader, ...fields } = page.fields;

    const categoryIds = (fields.mapAndMetricsImpactFunds ?? [])
      .map((fund) => fund?.sys.id)
      .filter((id) => typeof id === 'string');

    let mapItems: Array<{ label: string; locationCodes: string[] }> = [];
    if (categoryIds.length === 1) {
      // Split map items by pillars if only one category
      const [initiatives, pillars] = await Promise.all([
        getInitiatives({
          categoryId: categoryIds.filter(hasValue),
          status: ['ACTIVE'],
          limit: 100,
          skipContentfulData: true,
        }),
        getPillars({
          categoryId: categoryIds.filter(hasValue),
          skipContentfulData: true,
        }),
      ]);

      const contentfulPillars = await getEntries<TypePillarSkeleton>({
        content_type: 'pillar',
        'sys.id[in]': pillars.result.map((pillar) => pillar.id),
      });

      mapItems = contentfulPillars.items.map((pillar) => {
        const pillarSpecificInitiatives = initiatives.result.filter((initiative) =>
          initiative.pillarIds.includes(pillar.sys.id),
        );

        return {
          label: pillar.fields.title ?? pillar.fields.name,
          locationCodes: pillarSpecificInitiatives.flatMap(
            (initiative) => initiative.locationCodes,
          ),
        };
      });
    } else if (categoryIds.length > 1) {
      const impactFunds = (fields.mapAndMetricsImpactFunds ?? []).filter(hasValue);
      const initiativesPerCategory = await Promise.all(
        impactFunds.map((fund) =>
          getInitiatives({
            categoryId: [fund.sys.id],
            status: ['ACTIVE'],
            limit: 100,
            skipContentfulData: true,
          }),
        ),
      );

      mapItems = impactFunds.map((fund, index) => {
        const categorySpecificInitiatives = initiativesPerCategory[index].result ?? [];

        return {
          label: fund.fields.title ?? fund.fields.name ?? '',
          locationCodes: categorySpecificInitiatives.flatMap(
            (initiative) => initiative.locationCodes,
          ),
        };
      });
    }

    const [highlights1videos, highlights2videos, highlights3videos, highlights4videos] =
      await Promise.all([
        createHighlightVideo(fields.highlight1Image),
        createHighlightVideo(fields.highlight2Image),
        createHighlightVideo(fields.highlight3Image),
        createHighlightVideo(fields.highlight4Image),
      ]);

    return {
      props: {
        isGenericPage: true,
        title: fields.heroSectionTitle,
        hero: {
          title: fields.heroSectionTitle,
          paragraph: fields.heroParagraph,
          image: fields.heroImage,
          buttons: fields.heroCTAs?.map((cta) => ({
            text: cta?.fields.identifier,
            url: cta?.fields.externalUrl,
          })),
        },
        highlight1: {
          sectionTitle: fields.highlight1SectionTitle,
          text: fields.highlight1Text,
          buttons: linksToButtons(fields.highlight1Buttons),
          image: createHighlightImage(fields.highlight1Image, fields.highlight1ImageFit),
          landscapeVideo: highlights1videos?.landscapeVideo,
          portraitVideo: highlights1videos?.portraitVideo,
        },
        highlight2: {
          sectionTitle: fields.highlight2SectionTitle,
          text: fields.highlight2Text,
          buttons: linksToButtons(fields.highlight2Buttons),
          image: createHighlightImage(fields.highlight2Image, fields.highlight2ImageFit),
          landscapeVideo: highlights2videos?.landscapeVideo,
          portraitVideo: highlights2videos?.portraitVideo,
        },
        highlight3: {
          sectionTitle: fields.highlight3SectionTitle,
          text: fields.highlight3Text,
          buttons: linksToButtons(fields.highlight3Buttons),
          image: createHighlightImage(fields.highlight3Image, fields.highlight3ImageFit),
          landscapeVideo: highlights3videos?.landscapeVideo,
          portraitVideo: highlights3videos?.portraitVideo,
        },
        highlight4: {
          sectionTitle: fields.highlight4SectionTitle,
          text: fields.highlight4Text,
          buttons: linksToButtons(fields.highlight4Buttons),
          image: createHighlightImage(fields.highlight4Image, fields.highlight4ImageFit),
          landscapeVideo: highlights4videos?.landscapeVideo,
          portraitVideo: highlights4videos?.portraitVideo,
        },
        metrics: {
          title: fields.metricsSectionTitle,
          mapItems,
          values: fields.metricsList?.map((metric) => ({
            metricName: metric?.fields.title,
            metricValue: metric?.fields.metricValue,
            description: metric?.fields.description,
          })),
        },
        downloadForm: {
          sectionTitle: fields.formSectionTitle,
          title: fields.formSectionRichText?.fields.title,
          description: fields.formSectionRichText?.fields.description,
          form: fields.formSectionForm,
        },
        experts: {
          sectionTitle: fields.expertsSectionTitle,
          title: fields.expertsTitle,
          text: fields.expertsText,
          mainExpertsTitle: fields.expertsProfileTitle,
          mainExperts: fields.expertsProfileList?.map((expert) => ({
            name: expert?.fields.name,
            title: expert?.fields.title,
            imageUrl: expert?.fields.cutoutImage?.fields.file?.url,
            imageAlt:
              expert?.fields.cutoutImage?.fields.description ??
              expert?.fields.cutoutImage?.fields.title ??
              '',
            description: expert?.fields.profileDescription,
          })),
          secondaryExpertsTitle: fields.expertsSecondaryProfileTitle,
          secondaryExperts: fields.expertsSecondaryProfileList?.map((expert) => ({
            name: expert?.fields.name,
            title: expert?.fields.title,
            imageUrl: expert?.fields.cutoutImage?.fields.file?.url,
            imageAlt:
              expert?.fields.cutoutImage?.fields.description ??
              expert?.fields.cutoutImage?.fields.title ??
              '',
          })),
        },

        callToAction: {
          sectionTitle: fields.callToActionSectionTitle,
          text: fields.callToActionText,
          buttons: fields.callToActionButtons
            ?.filter(hasValue)
            .map(({ fields: { identifier, externalUrl } }) => ({
              label: identifier,
              href: externalUrl,
            })),
          backgroundImageUrl: fields.callToActionBackgroundImage?.fields.file?.url,
        },
        partnerLogos: {
          title: fields.partnerLogosTitle,
          logosList: fields.partnerLogosImageLinks?.map((partnerLogo) => ({
            link: partnerLogo?.fields?.link,
            image: {
              url: partnerLogo?.fields?.image?.fields.file?.url ?? '',
              name: partnerLogo?.fields?.image?.fields.file?.fileName,
              alt:
                partnerLogo?.fields.image?.fields.description ??
                partnerLogo?.fields.image?.fields.title ??
                '',
            },
          })),
        },
        contactUs: {
          sectionTitle: fields.contactUsSectionTitle,
          title: fields.contactUsTitle,
          sections: toFormCTASection(fields.contactUsSections),
        },
      },
      pageAppProps: {
        metaHeader: transformMetaHeaderType({
          metaHeader,
          overrides: {
            canonicalLink: `${config.HOST_URL}/${fields.slug}`,
          },
        }),
      },
    };
  }

  return await getPlainTextPageServerSideProps({ page, slug: page.fields.slug });
}

export const getServerSideProps = withAppProps<
  TemplatePageProps | GenericPageProps | PlainTextPageProps
>(async (context: GetServerSidePropsContext) => {
  try {
    const { slug, tags, locale } = getOldConfiguration(context);
    return await getLandingPageServerSideProps({
      slug,
      tags,
      locale,
      context,
      skipErrorLogging: true,
    });
  } catch (e) {
    let { name } = context.query;
    if (!name) throw new Error('No name provided');
    if (Array.isArray(name)) name = name.join('/');

    let page: TypeGenericPageTemplate | TypePlainTextPageTemplate;

    page = await getEntries<TypeGenericPageTemplateSkeleton>({
      content_type: 'genericPageTemplate',
      'fields.slug': name,
      include: 10,
    }).then(({ items }) => items[0]);

    if (!page) {
      page = await getEntries<TypePlainTextPageTemplateSkeleton>({
        content_type: 'plainTextPageTemplate',
        'fields.slug': name,
        include: 10,
      }).then(({ items }) => items[0]);
    }

    if (!page) {
      throw new UrlError(`No page found for slug ${name}`, { status: 404 });
    }

    return await getPageProps(page);
  }
});

export function isGenericPageProps(
  props: TemplatePageProps | GenericPageProps | PlainTextPageProps,
): props is GenericPageProps {
  return 'isGenericPage' in props && props?.isGenericPage === true;
}

export function isPlainTextPageProps(
  props: TemplatePageProps | GenericPageProps | PlainTextPageProps,
): props is PlainTextPageProps {
  return 'isPlainTextPage' in props && props?.isPlainTextPage === true;
}

export default function Page(props: TemplatePageProps | GenericPageProps | PlainTextPageProps) {
  if (isGenericPageProps(props)) {
    return <GenericPageTemplate {...props} />;
  } else if (isPlainTextPageProps(props)) {
    return <PlainTextPageTemplate {...props} />;
  }

  return <TopLevelPage {...props} />;
}
