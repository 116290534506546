import styled from '@emotion/styled';
import { colors } from '../../utils/styleguide';

const handleVariantImageColor = (color?: string) => {
  switch (color) {
    case colors.blackSecondary:
      return `
        filter: brightness(0) saturate(100%) invert(5%) sepia(7%) saturate(22%) hue-rotate(314deg) brightness(98%) contrast(97%);
        opacity: 0.7;
      `;
    case colors.white:
      return `
        filter: brightness(0) invert(1);
      `;
    default:
      return;
  }
};

type Props = {
  src?: string;
  color?: string;
  size?: string;
  alt?: string | null;
};

// eslint-disable-next-line @next/next/no-img-element
const StyledSvg = styled.img<{ color?: string }>`
  ${({ color }) => handleVariantImageColor(color)};
`;

export default function SvgImage({ color, src, size, alt }: Props) {
  if (!src) {
    return null;
  }

  return <StyledSvg src={src} alt={alt ?? undefined} color={color} width={size} height={size} />;
}
