import { breakpoints, colors, spacing } from '../../../utils/styleguide';
import styled from '@emotion/styled';
import Typography from '../text/Typography';
import PrimaryButton from '../buttons/PrimaryButton';
import SvgImage from '../SvgImage';
import SectionTitle from '../SectionTitle';
import Image from '../Image';
import { css } from '@emotion/react';
import { Document } from '@contentful/rich-text-types';
import RichText from '../../contentful-elements/RichText/RichText';
import Container from './Container';
import GhostButton from '../buttons/GhostButton';
import { Video } from '../../../utils/api/videoApi';
import BackgroundVideo from '../media/BackgroundVideo';
import { getStreamingSrc } from '../../../utils/helpers/video';

const ContentWrapper = styled.div<{ rightAlignedCopy?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  gap: ${spacing[6]}px;
  margin-top: ${spacing[5]}px;

  ${breakpoints.desktop} {
    flex-direction: ${({ rightAlignedCopy }) => (rightAlignedCopy ? 'row-reverse' : 'row')};
    padding-left: ${spacing[5]}px;
  }
`;

const LeftColWrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]}px;
  width: 100%;

  ${breakpoints.desktop} {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]}px;
  margin-top: ${spacing[4]}px;
`;

const ImageStack = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]}px;
  position: relative;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;

  ${breakpoints.desktop} {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
    height: auto;
    min-height: 300px;
  }
`;

const ImageOverlay = styled.div`
  border-radius: 2px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  background: linear-gradient(199deg, rgba(0, 0, 0, 0) 66.64%, rgba(0, 0, 0, 0.4) 90.27%);
`;

const ImageStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageCopyWrapper = styled.div`
  display: flex;
  gap: ${spacing[2]}px;
  align-items: center;
  bottom: 0;
  left: 0;
  position: absolute;
  padding: ${spacing[1]}px;
  color: ${colors.white};
`;

export type HighlightButton = { label?: string | null; href?: string | null };

type Props = {
  rightAlignedCopy?: boolean;
  sectionTitle?: string | null;
  title?: string | null;
  text?: Document | string | null;
  buttons?: HighlightButton[];
  buttonVariant?: 'primary' | 'text';
  images?: Partial<{
    imageUrl: string | null;
    imageAlt: string | null;
    imageText: string | null;
    imageIconUrl: string | null;
    imageIconAlt: string | null;
    objectFit: 'cover' | 'contain';
  }>[];
  landscapeVideo?: Video;
  portraitVideo?: Video;
  backgroundColor?: string;
  id?: string;
  withCheckmark?: boolean;
};

export default function HighlightLayout({
  rightAlignedCopy,
  sectionTitle,
  title,
  text,
  buttons,
  buttonVariant = 'text',
  images,
  landscapeVideo,
  portraitVideo,
  backgroundColor,
  id,
  withCheckmark = true,
}: Props) {
  if (
    !sectionTitle &&
    !text &&
    !images?.length &&
    !(landscapeVideo && portraitVideo) &&
    !buttons?.length
  ) {
    return null;
  }

  const allImagesContainImageUrls = Boolean(
    images?.length && images.every((image) => !!image.imageUrl),
  );
  const hasVideo = Boolean(landscapeVideo && portraitVideo);

  return (
    <Container id={id} backgroundColor={backgroundColor}>
      {sectionTitle && <SectionTitle title={sectionTitle} />}

      <ContentWrapper rightAlignedCopy={rightAlignedCopy}>
        {!!text && (
          <LeftColWrapper fullWidth={!hasVideo && !allImagesContainImageUrls}>
            {title && <Typography variant="h3">{title}</Typography>}

            {typeof text === 'string' ? (
              <Typography
                color={colors.blackSecondary}
                css={css`
                  white-space: pre-wrap;
                `}
              >
                {/* NOTE: we trim the text to remove any leading or trailing whitespace */}
                {text.trim()}
              </Typography>
            ) : (
              <RichText document={text} withCheckmark={withCheckmark} />
            )}

            {!!buttons?.length && buttonVariant === 'primary' && (
              <ButtonWrapper>
                {buttons?.map((button, index) => (
                  <PrimaryButton
                    key={index}
                    color="purple"
                    size="small"
                    style={{ width: 'fit-content' }}
                    label={button.label}
                    variant={index === 0 ? 'solid' : 'outline'}
                    trailingIconName="chevron-right"
                    href={button.href}
                  />
                ))}
              </ButtonWrapper>
            )}

            {!!buttons?.length && buttonVariant === 'text' && (
              <ButtonWrapper>
                {buttons?.map((button, index) => (
                  <GhostButton
                    key={index}
                    label={button.label}
                    trailingIconName="chevron-right"
                    color={colors.purple400}
                    hideUnderline
                    onClick={() => window.open(button.href ?? undefined, '_blank')}
                  />
                ))}
              </ButtonWrapper>
            )}
          </LeftColWrapper>
        )}

        {/* NOTE: we check landscapeVideo and portraitVideo explicitly as the type checker cannot infer it */}
        {hasVideo && landscapeVideo && portraitVideo && (
          <ImageStack fullWidth={!text}>
            <BackgroundVideo
              videoLargeScreenUrl={getStreamingSrc({ video: landscapeVideo })}
              videoSmallScreenUrl={getStreamingSrc({ video: portraitVideo })}
              posterLargeScreenUrl={landscapeVideo.metaData.posterSrc}
              posterSmallScreenUrl={portraitVideo.metaData.posterSrc}
              aspectRatios={{
                desktop: landscapeVideo.metaData.ratio || 16 / 9,
                tablet: landscapeVideo.metaData.ratio || 16 / 9,
                mobile: portraitVideo.metaData.ratio || 16 / 9,
              }}
              showControls={false}
              showDefaultControls
            />
          </ImageStack>
        )}

        {!hasVideo && !!images?.length && allImagesContainImageUrls && (
          <ImageStack fullWidth={!text}>
            {images?.map((image, index) => (
              <div
                key={index}
                style={{
                  position: 'relative',
                  minHeight: images?.length > 1 ? '150px' : '350px',
                  height: images.length === 1 ? '100%' : `${100 / images.length}%`,
                }}
              >
                <Image
                  maxWidth={{ desktop: !text ? 1200 : 600 }}
                  lazyLoad
                  url={image.imageUrl ?? ''}
                  css={css`
                    ${ImageStyle}
                    object-fit: ${image.objectFit ?? 'cover'};
                  `}
                  alt={image.imageAlt ?? ''}
                />
                {image.imageText && (
                  <>
                    <ImageOverlay />
                    <ImageCopyWrapper key={index}>
                      {image.imageIconUrl && (
                        <SvgImage
                          src={image.imageIconUrl}
                          alt={image.imageIconAlt}
                          color={colors.white}
                          size="24px"
                        />
                      )}
                      <Typography variant="overlineSmall" color={colors.white}>
                        {image.imageText}
                      </Typography>
                    </ImageCopyWrapper>
                  </>
                )}
              </div>
            ))}
          </ImageStack>
        )}
      </ContentWrapper>
    </Container>
  );
}
